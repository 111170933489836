import { useState } from 'react';
// import { Alert, Button } from '@mui/material';
import header_logo from '../assets/SynoriqLogo.bba84c8b32de2613a8a1.png';
import { API_BASE_URL } from '../constants/EnvConstants';
import { getToken, logoutUser } from '../utility/CommonFunctions';
import { Button, Grid, CircularProgress, Alert } from '@mui/material';
import { Sync, Logout } from '@mui/icons-material';
import { toast } from 'react-toastify';

const token = getToken();

const Header = () => {

  const [userSyncloading, setUserSyncLoading] = useState(false);
  const [roleSyncloading, setRoleSyncLoading] = useState(false);
  const [userRolesSyncloading, setUserRolesSyncLoading] = useState(false);

  const userSync = async () => {
    try {
      setUserSyncLoading(true);
      await (await fetch(`${API_BASE_URL}/user/sync-users`, {
        headers: {
          Authorization: `Bearer ${token}`
        },
        method: "POST"
      }).then(
        (response) => response.json()
      ).then(
          (data) => {
            if (data.status === 401) {
              logoutUser();
            } else if (data.status === 400) {
              toast.error(data.error);
            } else{
              toast.success("Users synchronized successfully!");
            }
          }
        ));
    } catch (err: any) {
      console.log(err.message);
      toast.error(err.message);
    } finally {
      setUserSyncLoading(false);
    }
  }

  const roleSync = async () => {
    try {
      setRoleSyncLoading(true);
      await (await fetch(`${API_BASE_URL}/lms/sync-roles`, {
        headers: {
          Authorization: `Bearer ${token}`
        },
        method: "POST"
      }).then(
        (response) => response.json()
      )
      .then(
        (data) => {
          if (data.status === 401) {
            logoutUser();
          } else if (data.status === 400) {
            toast.error(data.error);
          } else if(data.response){
            toast.success(data.data);
          } else{
            toast.error(data.error);
          }
        }
      ));
    } catch (err: any) {
        console.log(err.message);
        toast.error(err.message);
    } finally {
      setRoleSyncLoading(false);
    }
  }

  const userRoleSync = async () => {
    try {
      setUserRolesSyncLoading(true);
      await (await fetch(`${API_BASE_URL}/lms/sync-user-roles`, {
        headers: {
          Authorization: `Bearer ${token}`
        },
        method: "POST"
      }).then(
        (response) =>{
          if(response.status === 401){
            logoutUser();
          }
  
          return response.json();
        }
      )
      .then(
        (data) => {
          console.log('user role sync - ',data.data);

          if (data.status === 401) {
            logoutUser();
          } else if (data.status === 400) {
            toast.error(data.error);
          } else if(data.response){
            toast.success(data.data);
          } else{
            toast.error(data.error);
          }
        }
      ));
    } catch (err: any) {
        console.log(err.message);
        toast.error(err.message);
    } finally {
      setUserRolesSyncLoading(false);
    }
  }

  return (
    <div className="Header" style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
      <div>
        <img src={header_logo} alt='header_logo' />
      </div>
      <div>
        <Grid container spacing={2} justifyContent="flex-end">
          <Grid item>
            <Button
              variant="contained"
              color="primary"
              onClick={userSync}
              startIcon={userSyncloading ? <CircularProgress size={24} color="inherit" /> : <Sync />}
              disabled={userSyncloading}
            > Sync Users
            </Button>
          </Grid>
          <Grid item>
            <Button 
            variant="contained" 
            color="primary" 
            onClick={roleSync} 
            startIcon={roleSyncloading ? <CircularProgress size={24} color="inherit" /> : <Sync />}
            disabled={roleSyncloading}
            >
              Sync Roles
            </Button>
          </Grid>
          <Grid item>
            <Button 
            variant="contained" 
            color="primary" 
            onClick={userRoleSync}
            startIcon={userRolesSyncloading ? <CircularProgress size={24} color="inherit" /> : <Sync />}
            disabled={userRolesSyncloading}
            >
              Sync User-Roles
            </Button>
          </Grid>
          <Grid item>
            <Button variant="contained" color="secondary" onClick={logoutUser} startIcon={<Logout />}>
              Logout
            </Button>
          </Grid>
        </Grid>
      </div>
    </div>
  )
}

export default Header