// import * as React from 'react';
import { GridColDef } from '@mui/x-data-grid';
import  '../../App.scss';
import { Button } from '@mui/material';
import {DataTable} from "../../components/dataTable";
import { useNavigate } from 'react-router-dom';
import '../UserDetails/UserDetails.scss';
import { useEffect, useState } from 'react';
import { API_BASE_URL } from '../../constants/EnvConstants';
import { getBearerToken, getToken, logoutUser } from '../../utility/CommonFunctions';

function UserTable() {

  const navigate = useNavigate();
  let [rowsData, setRowData] = useState([]);

  useEffect(() => {
    fetch(`${API_BASE_URL}/user/fetch-all-users`, {
      headers: {
        Authorization: getBearerToken()
      }
    })
    .then(
      (response) =>{
        if(response.status === 401){
          logoutUser();
        }

        return response.json();
      }
    )
    .then(
      (data) => {
        setRowData(data.data.data);
      }
    );
  }, []);

  const columns: GridColDef[] = [
    { field: 'userId', headerName: 'User ID',  width: 200, },
    {
      field: 'name',
      headerName: 'Name',
      description: 'This column has a value getter and is not sortable.',
      sortable: false,
      width: 300,
    },
    {
      field: 'email',
      headerName: 'Email',
      description: 'This column has a value getter and is not sortable.',
      sortable: false,
      width: 300,
    },
    { 
      field: 'action', 
      headerName: 'Action', 
      width: 150 ,
      headerAlign: 'center',
      align: 'center',
      renderCell: (params) => (
        <Button onClick={()=>navigate('/userDetails', {state: {name: params.row.name, userId: params.row.userId}})}>Edit</Button>
      )
    }
  ];

  // const rowsData = UserList;

  return(
      <div>
        <div className="DataTable-Container"><DataTable rows={rowsData} columns={columns} /></div>
      </div>
  );
}

export default UserTable;