import * as React from "react";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { Button, Checkbox, FormControlLabel, FormGroup } from "@mui/material";
import "../features/UserDetails/UserDetails.scss";
import { useState, useEffect } from "react";
import AuthToken from "../datasets/AuthToken";
import { API_BASE_URL } from "../constants/EnvConstants";
import { getToken, logoutUser } from "../utility/CommonFunctions";

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

const token = getToken();

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `vertical-tab-${index}`,
    "aria-controls": `vertical-tabpanel-${index}`,
  };
}

interface Role {
  roleId: string;
  name: string;
  selected: boolean;
}

export default function VerticalTabs(props: any) {
  const [value, setValue] = React.useState(0);
  const [roles, setRoles] = React.useState<Role[]>(props.tabValue);
  const env = props.env;
  const userId = props.userId;
  const app = props.app;
  const [clients, setClients] = useState([]);
  const [selectedClient, setSelectedClient] = useState();
  let client: any;

  const fetchClients = () => {
    // Fecthing client  list
    return fetch(
      `${API_BASE_URL}/lms/client-list`,
      {
        headers: {
          "content-type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        method: "POST",
        body: JSON.stringify({
          env: env,
        }),
      }
    ).then((response) => {
      if(response.status === 401){
        logoutUser();
      }

      return response.json();
    });
  };

  const fetchRoles = () => {
    // Fecthing roles  list
    return fetch(
      `${API_BASE_URL}/lms/edit-user-roles`,
      {
        headers: {
          "content-type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        method: "POST",
        body: JSON.stringify({
          uid: userId,
          app: app,
          env: env,
          client: client,
        }),
      }
    ).then((response) => {
      if(response.status === 401){
        logoutUser();
      }

      return response.json();
    });
  };

  const updateRoles = () => {
    return fetch(
      `${API_BASE_URL}/lms/update-user-roles`,
      {
        headers: {
          "content-type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        method: "POST",
        body: JSON.stringify({
          uid: userId,
          app: app,
          env: env,
          client: selectedClient,
          roles: roles,
        }),
      }
    ).then((response) => {
      if(response.status === 401){
        logoutUser();
      }

      return response.json();
    });
  };

  useEffect(() => {
    if (props.env) {
      fetchData();
    }
  }, [props.env]);

  useEffect(() => {
    if (selectedClient) {
      client = selectedClient;
      setRolesData();
    }
  }, [selectedClient]);

  const fetchData = async () => {
    const clientsResponse = await fetchClients();
    setClients(clientsResponse.data);
    setSelectedClient(clientsResponse.data[0]);
    client = clientsResponse.data[0];
    setRolesData();
  };

  const setRolesData = async () => {
    const rolesResponse = await fetchRoles();
    setRoles(rolesResponse.data);
  };

  const selectAll = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newRoles = roles.map((role) => ({
      ...role,
      selected: event.target.checked,
    }));
    setRoles(newRoles);
  };

  const isAllSelected =
    roles != null && roles.length > 0 && roles.every((role) => role.selected);
  const handleSelect = (event: React.ChangeEvent<HTMLInputElement>) => {
    const roleName = event.target.name;
    const newRoles = roles.map((role, i) => {
      if (role.name === roleName) {
        return { ...role, selected: event.target.checked };
      }
      return role;
    });
    setRoles(newRoles);
  };

  const Clients = clients.map((tabItem: any, index: any) => {
    return (
      <Tab
        key={index}
        label={tabItem}
        {...a11yProps(index)}
        style={{ alignItems: "flex-start" }}
      />
    );
  });

  const rolesCheckboxes =
    roles != null &&
    roles.map((item: any, index: any) => {
      return (
        <FormControlLabel
          key={index}
          control={
            <Checkbox
              name={item.name}
              checked={item.selected}
              onChange={handleSelect}
            />
          }
          label={item.name}
          style={{ width: "300px", height: "50px", color: "#666666" }}
        />
      );
    });

  const ClientwiseRoleList = clients.map((tabItem: any, index: any) => {
    return (
      <div>
        <TabPanel value={value} index={index}>
          <div className="roles-header">
            <FormControlLabel
              control={
                <Checkbox onChange={selectAll} checked={isAllSelected} />
              }
              label="Select All"
              style={{ color: "#666666" }}
            />
            <Button
              variant="contained"
              onClick={updateRoles}
              style={{ marginLeft: "10px" }}
            >
              Submit
            </Button>
          </div>
          <FormGroup
            style={{ flexDirection: "row", justifyContent: "space-between" }}
          >
            {rolesCheckboxes}
          </FormGroup>
        </TabPanel>
      </div>
    );
  });

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
    setSelectedClient(clients[newValue]);
  };

  return (
    <div className="vertical-tab-container">
      <div className="clients-container">
        <Tabs
          orientation="vertical"
          variant="scrollable"
          value={value}
          onChange={handleChange}
          aria-label="Vertical tabs example"
          sx={{ borderRight: 1, borderColor: "divider" }}
          style={{ width: "250px", textAlign: "left" }}
        >
          {Clients}
        </Tabs>
      </div>
      <div className="roles-container">{ClientwiseRoleList}</div>
    </div>
  );
}
